import http from '@/utils/http/index'

/**
 * 争议订单信息
 * @param {*} id
 * @returns
 */
export function getDisputeOrder(id) {
  return http.get(`/dispute/customer/order/info?orderId=${id}`);
}

/**
 * 发起争议
 * @param {*} data
 * @returns
 */
export function applyDispute(data) {
  return http.post('/dispute/customer/saveOrDetail', data);
}

/**
 * 上传图片
 * @param {*} file
 * @returns
 */
export function uploadChatImg(file) {
  const data = new FormData(); // 创建form对象
  data.append('file', file);
  return http.post('/dispute/customer/chat/upload', data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
}

/**
 * 发送消息
 * @param {*} data
 * @returns
 */
export function sendMessage(data) {
  return http.post('/dispute/customer/chat/save', data);
}

/**
 * 获取争议详情信息
 * @param {*} token
 * @returns
 */
export function getDisputeInfo(token) {
  return http.get(`/dispute/customer/detail?token=${token}`);
}

/**
 * 申请仲裁
 * @param {*} data
 * @returns
 */
export function applyArbitration(data) {
  return http.post('dispute/customer/arbitration/apply', data);
}

/**
 * 结束争议
 * @param {*} data
 * @returns
 */
export function endDispute(data) {
  return http.post('/dispute/customer/close', data);
}
