// 提交时的空值判断
export function isEmptyValue(value) {
  return String(value) !== '0' && (!value || !String(value).trim() || String(value) === 'undefined' || String(value) === 'null');
}

export function getQueryString(name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  const r = window.location.search.substr(1).match(reg);
  if (r !== null) return unescape(r[2]);
  return null;
}

export function getEnumLabelByValue(emnus, value, valueKkey = '', labelName = '') {
  for (let i = 0; i < emnus.length; i++) {
    const item = emnus[i];
    let itemValue = '';
    if (valueKkey) {
      itemValue = item[valueKkey];
    } else {
      itemValue = item.value;
    }
    if (itemValue === value) {
      if (labelName) {
        return item[labelName];
      } else {
        return item.label;
      }
    }
  }
}
