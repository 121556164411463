<template>
  <div style="display: inline-block;">
    <img :src="src" @click="previewHandler" :style="styles" />
    <a-modal
      :title="null"
      :visible="visible"
      :maskClosable="true"
      :footer="null"
      @cancel="visible = false"
      :width="'fit-content'"
    >
      <p></p>
      <img :src="src" />
    </a-modal>
  </div>
</template>
<script>
export default {
  name: 'client-image',
  components: {
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    preview: {
      type: Boolean,
      default: true
    },
    styles: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      visible: false
    };
  },
  methods: {
    previewHandler() {
      this.visible = true;
    }
  }
};
</script>
<style lang="less">
</style>
