// 争议类型
export const DISPUTE_TYPE = [
  { label: 'message.dispute.typeOptions.unknow', value: 0 },
  { label: 'message.dispute.typeOptions.fraud', value: 1 },
  { label: 'message.dispute.typeOptions.unauthorized', value: 2 },
  { label: 'message.dispute.typeOptions.wrongGoods', value: 3 },
  { label: 'message.dispute.typeOptions.productNotReceived', value: 4 },
  { label: 'message.dispute.typeOptions.defectiveMerchandise', value: 5 },
  { label: 'message.dispute.typeOptions.incorrectTransaction', value: 6 },
  { label: 'message.dispute.typeOptions.wrongAddress', value: 7 },
  { label: 'message.dispute.typeOptions.creditNotProcessed', value: 8 }
]

export const DISPUTE_STATUS = [
  { label: 'message.dispute.status.0', value: 0 },
  { label: 'message.dispute.status.1', value: 1 },
  // { label: 'message.dispute.status.2', value: 2 },
  { label: 'message.dispute.status.3', value: 3 }
]
