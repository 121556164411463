<template>
  <a-spin :spinning="loading">
    <div v-if="disputeInfo">
      <a-row type="flex">
        <a-col flex="460px" style="max-width: 100%;">
          <div class="order">
            <div class="title">{{ $t('message.dispute.orderDetails') }}</div>
            <div class="status">
              <a-badge color="#FF764C" :text="$t(getEnumLabelByValue(DISPUTE_STATUS ,disputeInfo.dispute.status))" />
              <div class="content">
                <div>{{ $t('message.dispute.id') }}: {{ disputeInfo.dispute.id }}</div>
                <div>{{ $t('message.dispute.createdAt') }}: {{ disputeInfo.dispute.createdAt }}</div>
              </div>
            </div>
            <div class="info">
              <ul>
                <li v-for="(item, index) in info" :key="index">
                  <span class="label">{{ $t(item.label) }}:</span>
                  <span class="content"><span v-for="(valueKey, idx) in item.key" :key="idx"> {{ disputeInfo.orderInfo[valueKey] }} </span></span>
                </li>
              </ul>
            </div>
            <div class="actions">
              <a-button v-if="disputeInfo.dispute.status !== 3" type="success" size="large" @click="endDisputeHandler">{{ $t('message.dispute.endDispute') }}</a-button>
              <a-button v-if="disputeInfo.dispute.status === 0" type="primary" size="large" @click="applyArbitrationHandler" style="width: 200px;">{{ $t('message.dispute.applyArbitration') }}</a-button>
            </div>
          </div>
        </a-col>
        <a-col flex="auto" style="max-width: 100%;">
          <div class="chat" ref="chatBox">
            <div class="message-list" :style="{height: `${messageHeight}px`}" ref="messageListRef">
              <template v-for="(item, index) in disputeInfo.chatInfoList">
                <!-- 持卡人 -->
                <template v-if="item.senderType === 0">
                  <div class="customer item" :key="index">
                    <div class="title">Consumer {{ item.createdAt }}</div>
                    <div class="content">
                      <span class="message">{{ item.message }} <ClientImage :styles="{height: '30px'}" v-if="item.imgUrl" :src="item.imgUrl"></ClientImage></span>
                      <a-avatar class="avatar" :size="30" :src="require('@/assets/images/dispute/customer.png')" />
                    </div>
                  </div>
                </template>
                <!-- 商户 -->
                <template v-else-if="item.senderType === 1">
                  <div class="merchant item" :key="index">
                    <div class="title">Merchant {{ item.createdAt }}</div>
                    <div class="content">
                      <a-avatar class="avatar" :size="30" :src="require('@/assets/images/dispute/service.png')" />
                      <span class="message">{{ item.message }} <ClientImage :styles="{height: '30px'}" v-if="item.imgUrl" :src="item.imgUrl"></ClientImage></span>
                    </div>
                  </div>
                </template>
                <!-- Platform -->
                <template v-else-if="item.senderType === 2">
                  <div class="platform item" :key="index">
                    <div class="title">Platform {{ item.createdAt }}</div>
                    <div class="content">
                      <a-avatar class="avatar" :size="30" :src="require('@/assets/images/dispute/platform.png')" />
                      <span class="message">{{ item.message }} <ClientImage :styles="{height: '30px'}" v-if="item.imgUrl" :src="item.imgUrl"></ClientImage></span>
                    </div>
                  </div>
                </template>
                <!-- 系统消息 -->
                <template v-else-if="item.senderType === 3">
                  <div class="system item" :key="index">
                    <div class="title">{{ $t('message.dispute.systemMessage')}} {{ item['createdAt'] }}</div>
                    <div class="content">{{item.message}}</div>
                  </div>
                </template>
              </template>
            </div>
            <!-- 结束争议后不能在发送消息 -->
            <div>
              <div class="convenient">
                <a-upload
                  :disabled="disputeInfo.dispute.status === 3"
                  v-model="uploadFileList"
                  name="img"
                  class="img-uploader"
                  :show-upload-list="false"
                  :before-upload="beforeUpload"
                  :custom-request="uploadHandler"
                  :accept="'image/*'"
                >
                  <div>
                    <img style="width: 22px; height: 22px;" src="@/assets/images/dispute/img.png" />
                  </div>
                </a-upload>
              </div>
              <a-textarea v-model="content" class="chat-input" :rows="4" :autoSize="false" />
              <div class="actions">
                <div class="imgs">
                  <div v-if="uploadFileList && uploadFileList.length">
                    <div class="img-box">
                      <img :src="uploadFileList[0]" style="height: 32px" />
                      <a-icon type="close" class="close"  @click="deleteUploadFile"/>
                    </div>
                  </div>
                </div>
                <div class="btns" v-if="disputeInfo.dispute.status !== 3">
                  <a-button :disabled="disputeInfo.dispute.status === 3" @click="reset">{{ $t('message.dispute.reset') }}</a-button>
                  <a-button :disabled="disputeInfo.dispute.status === 3" @click="send" type="primary">{{ $t('message.dispute.send') }}</a-button>
                </div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>
<script>
import ClientImage from '@/components/image/index';
import { uploadChatImg, sendMessage, getDisputeInfo, applyArbitration, endDispute } from '@/views/dispute/api';
import { DISPUTE_STATUS } from '@/enums/dispute';
import { getEnumLabelByValue } from '@/utils/tools/utils';

export default {
  components: {
    ClientImage
  },
  data() {
    return {
      info: [
        { label: 'message.dispute.serialNumber', key: ['uniqueId'] },
        { label: 'message.dispute.orderNumber', key: ['transactionId'] },
        { label: 'message.dispute.txnAmount', key: ['tradeCurrency', 'tradeAmount'] },
        { label: 'message.dispute.enableRefundAmount', key: ['tradeCurrency', 'refundAmount'] },
        { label: 'message.dispute.txnTime', key: ['createAt'] },
        { label: 'message.dispute.txnWebsite', key: ['applicationDomain'] },
        { label: 'message.dispute.productInfo', key: ['message'] }
      ],
      disputeInfo: null,
      messageHeight: 0,
      loading: false,
      uploadFileList: [],
      content: '',
      DISPUTE_STATUS
    };
  },
  created() {
    this.messageHeight = window.document.body.clientHeight - 250;
    this.initData();
  },
  mounted() {
  },
  methods: {
    getEnumLabelByValue,
    endDisputeHandler() {
      this.loading = true;
      endDispute({
        disputeId: this.disputeInfo.dispute.id
      }).then((res) => {
        this.disputeInfo.dispute.status = 3;
        this.$message.success(this.$t('message.dispute.endDisputeSuccess'));
        this.initData();
      }).catch(() => {
        this.loading = false;
      })
    },
    applyArbitrationHandler() {
      this.loading = true;
      applyArbitration({
        disputeId: this.disputeInfo.dispute.id
      }).then((res) => {
        this.disputeInfo.dispute.status = 1;
        this.$message.success(this.$t('message.dispute.applyArbitrationSuccess'));
        this.initData();
      }).catch(() => {
        this.loading = false
      })
    },
    beforeUpload(file, fileList) {
      if (/image/.test(file.type)) {
        return true;
      }
      this.$message.error(this.$t('message.common.uploadImgErrorTip'));
      fileList.pop();
      return false;
    },
    uploadHandler({ file }) {
      this.loading = true;
      uploadChatImg(file).then((res) => {
        this.loading = false;
        this.uploadFileList = [res.data];
      }).catch(() => {
        this.uploadFileList.pop();
        this.loading = false;
      });
    },
    deleteUploadFile() {
      this.uploadFileList = [];
    },
    reset() {
      this.uploadFileList = [];
      this.content = '';
    },
    send() {
      if (!this.uploadFileList.length && !this.content.trim()) {
        this.$message.error(this.$t('message.common.inputMessageTip'));
        return;
      }
      this.loading = true;
      sendMessage({
        disputeId: this.disputeInfo.dispute.id,
        message: this.content.trim(),
        imgUrl: this.uploadFileList.length ? this.uploadFileList[0] : '',
        senderType: 0
      }).then((res) => {
        this.$message.success(this.$t('message.common.sendSuccessTip'));
        this.content = '';
        this.uploadFileList = [];
        this.initData();
      }).catch(() => {
        this.loading = false;
      });
    },
    initData() {
      this.loading = true;
      getDisputeInfo(this.$route.query.token).then((res) => {
        this.loading = false;
        if (res.status === 112) {
          this.$router.push({
            path: '/dispute/apply',
            query: {
              id: this.$route.query.id
            }
          });
        }
        this.disputeInfo = res.data;
        this.$nextTick(() => {
          this.$refs.messageListRef.scrollTop = this.$refs.messageListRef.scrollHeight;
        });
      }).catch(() => {
        this.loading = false;
      });
    }
  }
};
</script>
<style lang="less" scoped>
@import '~@/styles/common.less';
.order {
  padding: 0 25px;
  text-align: left;
  background-color: @white;
  .title {
    font-size: 18px;
    color: @title-color;
    line-height: 22px;
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid @border-color;
  }
  .status {
    border: 1px solid @warn-color;
    margin-top: 25px;
    border-radius: 4px;
    padding: 12px 15px;
    background-color: @bg-color;
    ::v-deep(.ant-badge) {
      margin-right: 10px;
      .ant-badge-status-text {
        color: @warn-color;
        font-size: 18px;
      }
    }
    .content {
      font-size: 12px;
      color: @text-color;
      margin-top: 8px;
      padding-left: 14px;
      line-height: 18px;
    }
  }
  .info {
    ul {
      li {
        margin-top: 15px;
        display: flex;
        .label {
          font-size: 16px;
          color: @text-color1;
          display: inline-block;
          width: 170px;
          flex: 0 0 170px;
        }
        .content {
          font-size: 16px;
          color: @text-color2;
        }
      }
    }
  }
  .actions {
    text-align: center;
    margin-top: 120px;
    padding-bottom: 30px;
    button {
      width: 150px;
      &:first-child {
        margin-right: 25px;
      }
    }
  }
}
.chat {
  position: relative;
  box-sizing: border-box;
  .message-list {
    padding: 12px 30px;
    box-sizing: border-box;
    overflow-y: auto;
    border-left: 1px solid @border-color;
    border-bottom: 1px solid @border-color;
    .item {
      margin-bottom: 4px;
    }
    .title {
      font-size: 12px;
      line-height: 17px;
      color: @text-color;
      margin-bottom: 2px;
    }
    .avatar {
      // ::v-deep(img) {
      //   width: 40px;
      //   height: 40px;
      // }
    }
    .system {
      width: 435px;
      max-width: 100%;
      margin: 10px auto;
      .content {
        border: 1px solid @border-color;
        background: @bg-color1;
        border-radius: 2px;
        font-size: 14px;
        line-height: 25px;
        padding: 7px;
        color: @text-color;
        display: inline-block;
      }
    }
    .customer {
      text-align: right;
      .content {
        border: 1px solid @border-color;
        display: inline-block;
        width: 550px;
        max-width: 90%;
        padding: 7px;
        background: @bg-color2;
        border-radius: 2px;
        position: relative;
        .avatar {
          margin-left: 15px;
          position: absolute;
          right: 7px;
        }
        .message {
          font-size: 16px;
          line-height: 30px;
          color: @title-color;
          display: inline-block;
          min-height: 30px;
          padding-right: 45px;
          text-align: left;
          width: 100%;
          word-break: break-word;
        }
      }
    }
    .merchant, .platform {
      text-align: left;
      .content {
        background-color: #FFF;
        border: 1px solid @border-color;
        display: inline-block;
        width: 550px;
        max-width: 90%;
        border-radius: 2px;
        padding: 7px;
        position: relative;
        min-height: 46px;
        .avatar {
          margin-right: 15px;
          position: absolute;
        }
        .message {
          font-size: 16px;
          line-height: 30px;
          color: @title-color;
          display: inline-block;
          padding-left: 45px;
          min-height: 30px;
          width: 100%;
          word-break: break-word;
        }
      }
    }
  }
  .convenient {
    text-align: left;
    height: 48px;
    background: rgba(87,111,136,0.5);
    padding:  13px 30px;
    box-sizing: border-box;
  }
  .chat-input {
    height: 122px;
    border-radius: 0;
    padding: 12px 30px 50px;
  }
  .actions {
    text-align: right;
    position: absolute;
    bottom: 15px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;
    box-sizing: border-box;
    .img-box {
      position: relative;
      .close {
        position: absolute;
        top: -10px;
        right: -10px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        cursor: pointer;
        background: rgba(0,0,0,0.5);
        color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    button {
      margin-left: 10px;
    }
  }
}
@media screen and (max-width: 768px) {
  .order {
    .actions {
      margin-top: 30px;
    }
  }
}
</style>
